// ts-check

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel'


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function Screenshots({ className, ...rest }) {
  const classes = useStyles();

//<Carousel showArrows={true} onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb}>

    var indexes = Array.from({length: 8}, (_, i) => i + 1);
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          color="textPrimary"
        >
          Screenshots
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Carousel showArrows={true} > 
            {
                indexes.map(i=>(
                    <div><img src={"static/screenshots/s"+i+".png"} /> </div>
                ))
            }
            </Carousel>        
      </Container>
    </div>
  );
}

Screenshots.propTypes = {
  className: PropTypes.string
};

export default Screenshots;
