import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Link,
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#111111',
    paddingTop: 64,
    paddingBottom: 64
  }
}));

function Footer1({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">

      <Box
          mtb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
        <a href="https://optimosoft.com">
           <img width="220" height="50" src="/static/logo_optimosoft.png"  />
           </a>
        </Box>
        <Box
          mtb={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
           <Link
        href="https://optimosoft.com"
          variant="body1"
          align="center"
          color="textPrimary"
        >
          Visit the Optimosoft website
        </Link>
        </Box>
      <Typography
          variant="body1"
          align="center"
          color="textPrimary"
        >
          © 2021
        </Typography>

      </Container>
    </div>
  );
}

Footer1.propTypes = {
  className: PropTypes.string
};

export default Footer1;
