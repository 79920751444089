import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import Footer1 from './Footer1';
import FAQS from './FAQS';
import Video from './Video';
import Screenshots from './Screenshots'


const useStyles = makeStyles(() => ({
  root: {}
}));

function HomeView() {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Hero />
      <Video />
      <FAQS />
      <Screenshots />
      <Footer1 />
    </Page>
  );
}

export default HomeView;
