import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  browseButton: {
    marginLeft: theme.spacing(2)
  }
}));

function Video({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">

        <Typography
          variant="h1"
          align="center"
          color="secondary"
        >
          Video - Trailer #1
        </Typography>
        <div
      className="video"
      style={{
        position: "relative",
        paddingBottom: "56.25%" /* 16:9 */,
        paddingTop: 25,
        height: 0
      }}
    >
            <iframe src='https://www.youtube.com/embed/5k2IqdSvwqk'
                    frameborder='0'
                    allow='autoplay; encrypted-media'
                    allowFullScreen
                    title='video'
                    style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
            />
            </div>
      </Container>
    </div>
  );
}

Video.propTypes = {
  className: PropTypes.string
};

export default Video;
