import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Divider,
  Toolbar,
  Typography,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/config';
import Logo from 'src/components/Logo';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

function TopBar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
      <Typography
            variant="h5"
            color="textPrimary"
          >
            EscapeGame360
          </Typography>
          {'\u00A0\u00A0'}
          <Typography
            variant="h5"
            color="secondary"
          >
            04/2021 release
          </Typography>
        <Box flexGrow={1} />
        <Hidden xsDown>
        <Typography
            variant="body1"
            color="textPrimary"
          >
            Follow us on
          </Typography>      
        </Hidden>  
        <Divider className={classes.divider} />
        
        <IconButton  size="large" component="span" component="a" href="https://twitter.com/optimosoft_ltd" >
          <TwitterIcon style={{fill: "#1DA1F2"}}/>
        </IconButton>
        
        &nbsp;
        <IconButton  size="large" component="span" component="a" href="https://www.facebook.com/optimosoft" >
        <FacebookIcon style={{fill: "#4267B2"}}/>
        </IconButton>
        &nbsp;
        <IconButton  size="large" component="span" component="a" href="https://www.youtube.com/channel/UCqhOwIdX2dlxT6uN5JOPigA" >
        <YouTubeIcon style={{fill: "#FF0000"}}/>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

export default TopBar;
