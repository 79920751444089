import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Divider,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    '& dt': {
      marginTop: theme.spacing(2)
    }
  }
}));

function FAQS({ className, ...rest }) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          color="textPrimary"
        >
          Frequently asked questions
        </Typography>
        <Box my={3}>
          <Divider />
        </Box>
        <Grid
          container
          spacing={3}
          component="dl"
        >
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              Technical
            </Typography>
            <Box mt={6}>
              <dd>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
What do you mean by 'Fully-scanned and 3D-augmented locations'?
                </Typography>
              </dd>
              <dt>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
We scan a historical place such as a castle by taking 360° spherical photos with a special camera on a tripod, positioned every 1 to 2 meters. A virtual environment is created from this scan. Then, additional 3D-graphics such as virtual characters, puzzles and animated machines are added to augment the environment and create a story-based game or any kind of web interactive experiences.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
Will it work on my device?
                </Typography>
              </dd>
              <dt>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
The game will work on standard browsers and standard devices such as laptops, tablets and iOS and Android smartphones. It will also work on selected Virtual Reality headsets such as the Oculus Quest. It uses webVR/webXR so it is launched from the browser, not a store.
                </Typography>
              </dt>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Typography
              variant="overline"
              color="secondary"
            >
              Game
            </Typography>
            <Box mt={6}>
              <dd>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
Is the game really free?
                </Typography>
              </dd>
              <dt>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
Yes, our first game of the series 'The Treasure of the Cathedral' will be free.
                </Typography>
              </dt>
            </Box>            
            <Box mt={6}>
              <dd>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
Is there a multiplayer mode? 
                </Typography>
              </dd>
              <dt>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
Yes, that's the plan. Players will be able to solve the escape-game in teams organised in virtual rooms with the ability to chat (text) and see each other wander. Players can also use Zoom or Skype for voice.
                </Typography>
              </dt>
            </Box>
            <Box mt={6}>
              <dd>
                <Typography
                  variant="h4"
                  color="textPrimary"
                >
Can such a game be made for my local museum/castle/cathedral/ruins?
                </Typography>
              </dd>
              <dt>
                <Typography
                  variant="body1"
                  color="textSecondary"
                >
For partnership and new projects, please head over to https://optimosoft.com and contact us. Especially if you work in the touristic or cultural sector and happen to manage (or know people who manage) such a building.
                </Typography>
              </dt>
            </Box>            
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

FAQS.propTypes = {
  className: PropTypes.string
};

export default FAQS;
